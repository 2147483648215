
import { defineComponent, computed, onMounted, reactive, ref, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { formatterDollars } from '@/utils/formatter'
import { formatTime } from '@/utils/dateTime'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import {
  getWarningContent,
  getOrderStatusColor,
  getAddressStatusColor,
} from '@/utils/orderUtils'
import { addressTitleMap, statusTitleMap } from '@/utils/constants'
import { Warning, InfoFilled, Message } from '@element-plus/icons'
import Pagination from '@/components/common/Pagination.vue'
import { useFetchData } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
    CustomDialog,
    Warning,
    InfoFilled,
    Message,
    Pagination,
  },
  setup() {
    const breadcrumbs = ['Payouts', 'Profit Detail', '']
    const title = 'Payouts'
    const content = computed(() => {
      return { breadcrumbs, title }
    })
    const columns = [
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        slots: { title: 'customTitle', customRender: 'orderName' },
        minWidth: '15',
      },
      {
        title: 'Customer',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Address',
        dataIndex: 'addressValid',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Revenue',
        dataIndex: 'revenue',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'FFM Cost',
        dataIndex: 'sellerAmount',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Total fees',
        dataIndex: 'total_fees',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Profit',
        dataIndex: 'profit',
        minWidth: '10',
        align: 'center',
      },
    ]
    const params = reactive<any>({ page: 1, limit: 10 })

    const baseResource = 'profits/logs'

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    onMounted(async () => {
      await fetchData()
    })
    watch(params, fetchData)

    return {
      documents,
      content,
      columns,
      isLoading,
      addressTitleMap,
      statusTitleMap,
      formatterDollars,
      formatTime,
      getWarningContent,
      getOrderStatusColor,
      getAddressStatusColor,
      params,
      total,
    }
  },
})
